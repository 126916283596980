.apps {
	.row {
		display:flex;
		align-items: stretch;
		align-content: stretch;
		margin-bottom: -8px;
		margin-top: -3px;

		.lob-cards {
			padding-top:15px;
			padding-bottom: 15px;;


			.card {
				height:100%;
				padding-bottom: 10px;
				padding-left: 10px;
				padding-right: 10px;
				.card-body::before {
					display:block;
					margin-bottom: 100%;
				}

				.card-body {
					padding: 0px;
				}

				.card-title {
					margin-top: 0.25rem;
					margin-bottom: 0px;
				}

			}

			img {
				width: 5rem;
				height: 5rem;
				align-self: center;
				margin-top: 1rem;
			}
	
			p {
				font-size: 0.75rem;
				text-align: center;
			}
		}


	}


}
