.announcement {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: -5px;
}

.announcement .list-group {
	margin-top: 5px;
}

.ac-container.ac-adaptiveCard {
  background-color: white;
  margin: 2.5rem 0 !important;
  position: absolute;
}

.an-head {
  font-weight: bold;
  font-size: 1.063rem;
  color: #f6f6f6;
}

.an-img {
  height: 19.375rem;
  display: block;
}

.ann-img {
  display: block;
  position: relative;
  margin: auto;
}

.news-item {
	display:flex;
	flex-direction: row;
}

.news-item img {
	width: 25%;
	margin-right: 1.25rem;
}

.news-item-text-container {
	flex-grow: 4;
	max-height: 60px;
	overflow: hidden;
}

.news-item-text-container h3 {
	font-size: 14px;
	font-weight:bold;
}

.news-item-text-container p {
	font-size: 12px;
}
