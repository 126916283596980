.news {
	margin-top: 20px;

	.news-head {
		display:flex;
		justify-content: space-between;
	}
	
	.news-title {
		margin: 0 1rem 0 1rem;
		font-weight: bold;
		font-size: 1.063rem;
		color: #f6f6f6;
	  }

	.carousel3 {
		margin-top: 0.5em;
		.carousel {
			display:flex;
			flex-direction: column;
			flex-wrap: nowrap;

			.carousel-inner {
				order:1;
			}

			.interaction-wrapper {
				display:flex;
				justify-content: start;

				.interaction-element {
					display:flex;

					margin: 0.5rem 0.5rem 0.5rem 0.5rem;

					p {
						margin: 0rem 0.5rem 0rem 0rem;
					}

				}

			}

			.carousel-indicators {
				order:2;
				position: relative;
				padding: 0;
				// top: 19.8rem;
				width: 100%;
				margin: 0.5rem 0em 0em 0em;
	
			  
				li {
					width: 0.438rem;
					height: 0.438rem;
					background: #c4c4c4;
					border-radius: 50%;
					border: none;
				}
			}
		}




	}
	  
	  .car-card {
		background: #ffffff;
		border: 0.063rem solid #e5e5e5;
		box-sizing: border-box;
		box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
		border-radius: 0.25rem;
		position: absolute;
		margin-top: 4%;
	  }
	  
	  .car-head {
		height: auto;
		margin: 1rem 1rem 0 1rem;
		font-weight: 500;
		font-size: 0.875rem;
		color: #393f3e;
	  }
	  
	  .car-date {
		margin: 0 1rem 1rem 1rem;
		font-weight: 500;
		font-size: 0.75rem;
		color: #393f3e;
		opacity: 0.6;
	  }
	  
	  .car-desc {
		width: auto;
		height: 50px;
		margin: 1.063rem;
		font-weight: 500;
		font-size: 0.875rem;
		color: #393f3e;
		flex-wrap: nowrap;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-align: justify;
		overflow: auto;
	  }
	  

	  
	.carousel-caption {
		position: absolute;
		bottom: 0px;
		right: 0%;
		left: 0%;
		z-index: 10;
		padding: 10px;
		padding-bottom: 0px;
		color: #fff;
		background-color: rgba(39, 39, 39, 0.71);
		width: 100%;
	}
	  
	.carousel-caption h3 {
		font-size: 14px;
		font-weight: bold;
	}
	  
	.carousel-caption p {
		font-size: 12px;
		max-height: 60px;
	}
	  
	.carousel-item img {
		  height:auto;
	}
	  
	.w-100 {
		height: 25rem;
		object-fit: scale-down;
	}
	  
	.see-more3 {
		font-weight: 500;
		font-size: 0.75rem;
		color: #a2a5a5;
		margin: 4px 0px 0 0;
	}
	  
	.see-more3::before {
		content: "\E449";
		font-family: "Teams Assets";
		font-size: 1rem;
		line-height: 1.1;
		color: #a2a5a5;
		float: right;
	}
	  
	// @media screen and (max-width: 26.563rem) {
	// 	.car-card {
	// 	  margin-top: 11%;
	// 	}
	  
	// 	.w-100 {
	// 	  height: 11.313rem;
	// 	  object-fit: cover;
	// 	}
	  
	// 	.car-desc {
	// 	  height: 6.25rem;
	// 	  font-size: 0.75rem;
	// 	}
	// }
	  
	// @media screen and (max-width: 20rem) {
	// 	.carousel-indicators {
	// 		top: 29.5rem;
	// 	}
	// }
}
