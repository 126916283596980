.header {
    position: relative;
    text-align: center;
	color: white;
	margin-top: 55px;
}

.header h1{
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    left:0%;
    max-width: 80%;
    background-color: rgba(71, 99, 123, 0.85);
    padding: 10px 20px;
    margin-left: 20px;
    transform: translate(-0%, -50%);
}

.newsContents h4 {
    margin-top: 30px;
}

.newsContents {
    margin-top: 30px;
    padding: 0px 20px;
}