@font-face {
  font-family: "Teams Assets";
  src: url("../../assets/fonts/TeamsAssets-Light.woff") format("woff"),
    url("../../assets/fonts/TeamsAssets-Light.woff2") format("woff2");
  font-style: normal;
}

.container {
  background-color: #47637b;
  padding: 0;
}

.row.custom-row {
	margin-left:0px;
	margin-right:0px;
}

.banner-logo {
  height: 10rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.65%, #000000 125%),
    url("../../assets/images/CS-Background.png") no-repeat bottom;
  background-size: cover;
}

.row-title {
	font-weight: bold;
	font-size: 1.063rem;
	color: #f6f6f6;
	/* margin: 1rem 1rem 0 0rem; */
	display: inline-block;
}

.row-header {
	display:flex;
	justify-content: space-between;
}

#content>.row {
	margin-top: 1rem;
}

.card-title {
	font-size: 0.875rem;
	color: #2e3333;
	width: 100%;
	overflow: hidden;
	flex-wrap: nowrap;
	text-overflow: ellipsis;
	font-weight: bold;
	text-align: center;
}

.greet {
  margin-top: 0.5rem;
  position: relative;
  display: inline-flex;
}

.card {
  background: #f6f6f6;
  box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  /* margin: 0 1rem; */
  /* margin-top: 1%; */
  /* height: 3.938rem; */
  border: none;
}

.cd2 {
  height: auto;
}

.hw-are-u-feeling {
  width: 100%;
  height: 8rem;
}

.news {
}



button#login {
  top: 50%;
  position: absolute;
  left: 50%;
  background-color: #464775;
  color: whitesmoke;
  width: 6.25rem;
  height: 2.5rem;
  border: navajowhite;
  cursor: pointer;
  margin: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader {
  top: 50%;
  position: absolute;
  left: 50%;
  margin: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.greet {
  margin-top: 0.5rem;
  position: relative;
  display: inline-flex;
}

.main-heading {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.188rem;
  line-height: 2.25rem;
  color: #f6f6f6;
}

/* @media screen and (max-width: 26.563rem) {
  .banner-logo {
    height: 8rem;
  }
  .hw-are-u-feeling {
    height: auto;
  }
  .feedback {
    margin: 0 1rem 0 1rem;
  } */

/* } */
