/* .new-team {
  position: absolute;
  margin: 0 1rem 1rem 1rem;
  height: 1.25rem;
  font-weight: bold;
  font-size: 1.063rem;
  line-height: 1.25rem;
  color: #f6f6f6;
} */

.new-team {
	
	
	// margin: 20px;
	// margin: 0rem 1rem 1rem 0rem;
	font-weight: bold;
	font-size: 1.063rem;
	// line-height: 1.25rem;
	color: #f6f6f6;

	.container {
		padding-left: 0px;
		padding-right: 0px;
	}

	.member-cards {
		display:flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.card-img-top {
	  border-radius: 50%;
	  width: 3.5rem;
	  height: 3.5rem;
	  align-self: center;
	  margin-top: 1rem;
	}
	
	.card-body {
	  padding: 0.25rem !important;
	  display:flex;
	  flex-direction: column;
	  align-items: center;
	}

	.card {
		margin: 0px;
		padding: 0.3rem;
	}

	
	.designation {
	  font-size: 0.75rem;
	  color: #6f7372;
	  width: 100%;
	  overflow: hidden;
	  flex-wrap: nowrap;
	  text-overflow: ellipsis;
	}
	
	.hi {
	  background: #f4c86b;
	  border-radius: 1rem;
	  width: 6rem;
	  height: 2rem;
	  line-height: 1;
	  color: white;
	  //margin: 0% 25% 0% 50%;
	}
}
