.design-logo {
  position: absolute;
  height: 3rem;
  width: auto;
  margin-left: 1.063rem;
  margin-top: 6.125rem;
  -webkit-animation: rotation 20s infinite linear;
  animation: rotation 20s infinite linear;
}

.design-logo-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 4.375rem;
  margin-top: 5.625rem;
  position: absolute;
  animation: linear;
  animation-name: fadeIn;
  animation-duration: 5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 26.563rem) {
  .design-logo-title {
    margin-top: 2.05rem;
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .design-logo {
    margin-top: 2.425rem;
  }
}
